import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import profileService from '../data/ProfileService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import finalWishesService from '../data/FinalWishesService';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WidgetContainer from '../components/WidgetContainer';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PetWishesDialog from '../components/PetWishesDialog';
import FuneralArrangementsDialog from '../components/FuneralArrangementsDialog';
import CareAndHealthDialog from '../components/CareAndHealthDialog';
import { CardActionArea } from '@mui/material';
import LoaderIcon from '../components/LoaderIcon';

const FinalWishesPage = () => {
   const funeralArrangementsDialogRef = useRef();
   const careAndHealthDialogRef = useRef();
   const petWishesDialogRef = useRef();

   const [funeralArrangementsDialogOpen, setFuneralArrangementsDialogOpen] = useState(false);
   const [careAndHealthDialogOpen, setCareAndHealthDialogOpen] = useState(false);
   const [petWishesDialogOpen, setPetWishesDialogOpen] = useState(false);

   const [funeralArrangementsLoading, setFuneralArrangementsLoading] = useState(false);
   const [careAndHealthLoading, setCareAndHealthLoading] = useState(false);
   const [petWishesLoading, setPetWishesLoading] = useState(false);

   const [funeralArrangements, setFuneralArrangements] = useState();
   const [careAndHealth, setCareAndHealth] = useState();

   const init = async () => {
      setFuneralArrangementsLoading(true);
      if (funeralArrangementsDialogRef.current) {
         await funeralArrangementsDialogRef.current.loadFuneralArrangements();
         const arrangements = await finalWishesService.getMyFuneralArrangements();
         setFuneralArrangements(arrangements);
         funeralArrangementsDialogRef.current.setState(arrangements);
      }
      setFuneralArrangementsLoading(false);
      setCareAndHealthLoading(true);
      if(careAndHealthDialogRef.current) {
         await careAndHealthDialogRef.current.loadCareAndHealth();
         const care = await finalWishesService.getMyCareAndHealth();
         setCareAndHealth(care);
         careAndHealthDialogRef.current.setState(care);
      }
         setCareAndHealthLoading(false);
   };

   useEffect(() => {
      init();
      checkProfileExists();
   }, []);

   const history = useHistory();

   const checkProfileExists = async () => {
      const profileExists = await profileService.getProfileExists();
      if (!profileExists) history.push('/get-started');
   };

   const saveFuneralArrangements = async (funeralArrangements) => {
      setFuneralArrangementsLoading(true);
      // console.log(funeralArrangements)
      await finalWishesService.putMyFuneralArrangements(funeralArrangements);
      setFuneralArrangements(funeralArrangements);
      funeralArrangementsDialogRef.current.setState(funeralArrangements);
      setFuneralArrangementsLoading(false);
      setFuneralArrangementsDialogOpen(false);
      
   };

   const saveCareAndHealth = async (careAndHealth) => {
      setCareAndHealthLoading(true);
      // console.log(careAndHealth)
      await finalWishesService.putMyCareAndHealth(careAndHealth);
      setCareAndHealth(careAndHealth);
      careAndHealthDialogRef.current.setState(careAndHealth);
      setCareAndHealthLoading(false);
      setCareAndHealthDialogOpen(false);
   };

   const onFuneralArrangementsClick = async () => {
      await setFuneralArrangementsDialogOpen(true);
      if (funeralArrangementsDialogRef.current) {
         // setFuneralArrangementsLoading(true);
         // funeralArrangementsDialogRef.current.setState(funeralArrangements);
         // await funeralArrangementsDialogRef.current.loadFuneralArrangements();
         funeralArrangementsDialogRef.current.setState(funeralArrangements);
         // setFuneralArrangementsLoading(false);
      }
   };

   const onCareAndHealthDialogClick = async () => {
      await setCareAndHealthDialogOpen(true);
      if (careAndHealthDialogRef.current) {
         careAndHealthDialogRef.current.setState(careAndHealth);
      }
   };

   const onPetWishesClick = async () => {
      setPetWishesLoading(true);
      if (petWishesDialogRef.current)
         await petWishesDialogRef.current.loadMyPetWishes();
      setPetWishesLoading(false);
      setPetWishesDialogOpen(true);
   };

   return (
      <Fragment>
         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
               <Grid item xs={12} md={12}>
                  <WidgetContainer
                     title='Final Wishes'
                     icon={FavoriteBorderIcon}
                     color='#00a5de'
                  >
                     <CardContent>
                        <Grid container spacing={3}>
                           <Grid item xs={12} md={6} lg={4}>
                              <Card
                                 sx={{ height: '100%', borderRadius: '0.9em' }}
                                 elevation={3}
                              >
                                 {funeralArrangementsLoading ? (
                                    <LoaderIcon />
                                 ) : (
                                    <CardActionArea
                                       onClick={onFuneralArrangementsClick}
                                       sx={{
                                          height: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                       }}
                                    >
                                       <CardMedia
                                          component='img'
                                          alt='leaves'
                                          height='160'
                                          image='/funeral-480.jpg'
                                       />
                                       <CardContent>
                                          <Typography
                                             gutterBottom
                                             variant='h5'
                                             component='div'
                                          >
                                             Funeral &amp; Arrangements
                                          </Typography>
                                          <Typography
                                             variant='body2'
                                             color='text.secondary'
                                          >
                                             Create your ceremony and define any
                                             special wishes and instructions.
                                             Decide who can attend and list any
                                             arrangements which have already
                                             been made.
                                          </Typography>
                                       </CardContent>
                                    </CardActionArea>
                                 )}
                              </Card>
                           </Grid>
                           <Grid item xs={12} md={6} lg={4}>
                              <Card
                                 sx={{ height: '100%', borderRadius: '0.9em' }}
                                 elevation={3}
                              >
                                 {careAndHealthLoading ? (
                                    <LoaderIcon />
                                 ) : (
                                    <CardActionArea
                                       onClick={onCareAndHealthDialogClick}
                                       sx={{
                                          height: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                       }}
                                    >
                                       <CardMedia
                                          component='img'
                                          alt='stethoscope'
                                          height='160'
                                          image='/stethoscope-480.jpg'
                                       />
                                       <CardContent>
                                          <Typography
                                             gutterBottom
                                             variant='h5'
                                             component='div'
                                          >
                                             Special Care &amp; Health Decisions
                                          </Typography>
                                          <Typography
                                             variant='body2'
                                             color='text.secondary'
                                          >
                                             Allow which decisions can be made
                                             when and by whom, concerning your
                                             health.
                                          </Typography>
                                       </CardContent>
                                    </CardActionArea>
                                 )}
                              </Card>
                           </Grid>

                           <Grid item xs={12} md={6} lg={4}>
                              <Card
                                 sx={{ height: '100%', borderRadius: '0.9em' }}
                                 elevation={3}
                              >
                                 {petWishesLoading ? (
                                    <LoaderIcon />
                                 ) : (
                                    <CardActionArea
                                       onClick={onPetWishesClick}
                                       sx={{
                                          height: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                       }}
                                    >
                                       <CardMedia
                                          component='img'
                                          alt='guard'
                                          height='160'
                                          image='/pet-wishes-480.jpg'
                                       />
                                       <CardContent>
                                          <Typography
                                             gutterBottom
                                             variant='h5'
                                             component='div'
                                          >
                                             Pet Wishes
                                          </Typography>
                                          <Typography
                                             variant='body2'
                                             color='text.secondary'
                                          >
                                             List your pets and decide who
                                             should take care of them. Capture
                                             any special considerations, diets
                                             and accommodations.
                                          </Typography>
                                       </CardContent>
                                    </CardActionArea>
                                 )}
                              </Card>
                           </Grid>
                           <Grid item xs={12} md={6} lg={4}>
                              <Card
                                 sx={{ height: '100%', borderRadius: '0.9em' }}
                                 elevation={3}
                              >
                                 <CardMedia
                                    component='img'
                                    alt='signing document'
                                    height='160'
                                    image='/create-will-480.jpg'
                                 />
                                 <CardContent>
                                    <Typography
                                       gutterBottom
                                       variant='h5'
                                       component='div'
                                    >
                                       Create My Will
                                    </Typography>
                                    <Typography
                                       variant='body2'
                                       color='text.secondary'
                                    >
                                       Define details regarding your will.
                                    </Typography>
                                 </CardContent>
                                 <CardActions>
                                    <Button size='small' disabled={true}>
                                       Coming soon...
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                           <Grid item xs={12} md={6} lg={4}>
                              <Card
                                 sx={{ height: '100%', borderRadius: '0.9em' }}
                                 elevation={3}
                              >
                                 <CardMedia
                                    component='img'
                                    alt='attorney'
                                    height='160'
                                    image='/power-of-attorney-480.jpg'
                                 />
                                 <CardContent>
                                    <Typography
                                       gutterBottom
                                       variant='h5'
                                       component='div'
                                    >
                                       Power of Attorney
                                    </Typography>
                                    <Typography
                                       variant='body2'
                                       color='text.secondary'
                                    >
                                       Define who has Power of Attorney to make
                                       legal decisions in the event you are not
                                       able to make them for yourself.
                                    </Typography>
                                 </CardContent>
                                 <CardActions>
                                    <Button size='small' disabled={true}>
                                       Coming soon...
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                           <Grid item xs={12} md={6} lg={4}>
                              <Card
                                 sx={{ height: '100%', borderRadius: '0.9em' }}
                                 elevation={3}
                              >
                                 <CardMedia
                                    component='img'
                                    alt='guard'
                                    height='160'
                                    image='/guard-480.jpg'
                                 />
                                 <CardContent>
                                    <Typography
                                       gutterBottom
                                       variant='h5'
                                       component='div'
                                    >
                                       Legal Guardian for Children
                                    </Typography>
                                    <Typography
                                       variant='body2'
                                       color='text.secondary'
                                    >
                                       List your children and who should become
                                       their legal guardian.
                                    </Typography>
                                 </CardContent>
                                 <CardActions>
                                    <Button size='small' disabled={true}>
                                       Coming soon...
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                        </Grid>
                     </CardContent>

                     {/* <CardContent>
                        {finalWishesEditMode ? <FinalWishesForm ref={finalWishesFormRef} addressStatesProvinces={addressStatesProvinces.data} /> : <FinalWishesDisplay finalWishes={finalWishes.data} />}
                     </CardContent>
                     <CardActions>
                        {finalWishesEditMode ? <Fragment>
                           <Button variant='outlined' onClick={() => setFinalWishesEditMode(false)}>Cancel</Button>
                           <Button variant='contained' onClick={saveMyFinalWishes}>Save</Button>
                        </Fragment> : null }
                     </CardActions> */}
                  </WidgetContainer>
               </Grid>
            </Grid>
         </Box>

         <FuneralArrangementsDialog
            ref={funeralArrangementsDialogRef}
            open={funeralArrangementsDialogOpen}
            handleClose={() => setFuneralArrangementsDialogOpen(false)}
            handleSaveFuneralArrangements={saveFuneralArrangements}
         />

         <CareAndHealthDialog
            ref={careAndHealthDialogRef}
            open={careAndHealthDialogOpen}
            handleClose={() => setCareAndHealthDialogOpen(false)}
            handleSaveCareAndHealth={saveCareAndHealth}
         />

         <PetWishesDialog
            ref={petWishesDialogRef}
            open={petWishesDialogOpen}
            handleClose={() => setPetWishesDialogOpen(false)}
         />
      </Fragment>
   );
};

export default FinalWishesPage;
